import moment from "moment";
export default {
  methods: {
    addComma(val) {
      if (val == 0) {
        return 0;
      } else {
        return String(val).replace(/(\d)(?=(\d\d\d)+(?!\d))/g, "$1,");
      }
    },
    moment: function (date) {
      return moment(date).format("YYYY/MM/DD");
    },
    // tokenCheck(obj){
    //   this.$store.dispatch('tokenCheck')
    // }
  },
};
