<template>{{ today }}現在</template>

<script>
import moment from "moment";
export default {
  name: "LastRace",
  data() {
    return {
      today: "",
    };
  },
  created: function () {
    this.today = moment(new Date()).format("YYYY年MM月DD日");
  },
};
</script>
